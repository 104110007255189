import { IBrand } from "@/types/whitelabel";
import dinheirow from "@/whitelabel/labels/dinheirow";
import brands from "./labels";
import WhitelabelResource, { IGetPartner } from "@/resources/onboard/WhitelabelResource";

export interface WhitelabelHandlerProps {
  whitelabelResource: WhitelabelResource;
  hostname: string;
  pathname: string;
  envBrand?: string;
}

class WhiteLabelHandler {
  brand: IBrand = dinheirow;

  whitelabelResource: WhitelabelResource;
  hostname = "";
  pathname = "";
  envBrand = "";

  constructor({ whitelabelResource, envBrand, hostname, pathname }: WhitelabelHandlerProps) {
    this.whitelabelResource = whitelabelResource;
    this.hostname = hostname;
    this.pathname = pathname;
    this.envBrand = envBrand || "";

    this.brand = this.getDefaultBrand();
  }

  getDefaultBrand() {
    const [urlBrandName] = this.hostname
      .replaceAll("dev", "")
      .replaceAll("stage", "")
      .replaceAll("-", "")
      .split(".");

    const urlBrand = urlBrandName && brands.find(({ brand }) => brand.includes(urlBrandName));
    const envBrand = this.envBrand && brands.find(({ brand }) => brand.includes(this.envBrand));

    return urlBrand || envBrand || dinheirow;
  }

  async getBrand() {
    let foundPartner: IGetPartner | null = null;

    const [brandName, partnerCode] = this.pathname.slice(1).split("/");
    if (!brandName) return this.brand;

    const foundBrand = await this.whitelabelResource.getBrand(brandName);
    if (!foundBrand) return this.brand;

    if (partnerCode) foundPartner = await this.whitelabelResource.getPartner(partnerCode);

    Object.entries(foundBrand).forEach(([key, value]) => {
      if (value === null || value === undefined) delete foundBrand[key as keyof IBrand];
    });

    let finalBrand: IBrand = {
      ...dinheirow,
      ...foundBrand,
      flowMessages: {
        ...dinheirow.flowMessages,
        ...foundBrand.flowMessages,
      },
      partnerCode: foundPartner?.code || foundBrand.partnerCode || "partner_dinheirow",
      colors: { ...dinheirow.colors, ...foundBrand.colors },
    };

    if (foundPartner) finalBrand.baseUrl += `/${foundPartner.code}`;

    finalBrand = {
      ...finalBrand,
      specificRoutes: [
        {
          path: "/",
          name: "Login",
          meta: { requiresAuth: false },
          component: () => import("@/pages/login/LoginPage.vue"),
        },
        ...(finalBrand.specificRoutes || []),
      ],
    };

    this.brand = finalBrand;
    return finalBrand;
  }
}

export default WhiteLabelHandler;
